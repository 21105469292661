//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-76:_928,_896,_828,_608,_104,_984,_913,_552;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-76')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-76', "_928,_896,_828,_608,_104,_984,_913,_552");
        }
      }catch (ex) {
        console.error(ex);
      }